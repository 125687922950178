<template>
  <div class="flex w-full inline-flex justify-between font-poppins">
    <div>
      <h3 class="text-2xl md:text-3xl leading-6 text-gray-900">
        My orders
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Orders and details.
      </p>
    </div>
    <div>
      <button @click="logout" class="bg-gray-800 h-12 rounded-xl text-gray-50 hover:bg-gray-100 hover:text-gray-900 px-4 hover:border-gray-900 hover:border">Log out</button>
    </div>
  </div>

  <div class="my-4">
    <OrderSummary v-for="order in orders" :key="order.id" :order="order"></OrderSummary>
  </div>

</template>

<script>
import axios from "axios";
import OrderSummary from "../components/OrderSummary";

export default {
  name: "MyAccount",
  components: {OrderSummary},
  data() {
    return {
      orders: []
    }
  },
  mounted() {
    document.title = "My Orders | Durban"
    this.getMyOrders()
  },
  methods: {
    logout() {
      axios.defaults.headers.common['Authorization'] = ''

      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem('userid')
      this.$store.commit('removeToken')
      this.$store.commit('logout')

      this.$router.push('/')
    },
    async getMyOrders() {
      this.$store.commit('setIsLoading', true)
      await axios
          .get('/api/v1/orders/')
          .then(response => {
            this.orders = response.data
          })
          .catch(error => {
            console.log(error)
          })
      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style scoped>

</style>
