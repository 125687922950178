<template>
  <div class="border rounded-xl shadow mb-4 p-6 font-poppins">
    <h1 class="text-md md:text-lg mb-6">Order from {{ this.date.getDate() }}/{{ this.date.getMonth() + 1 }}/{{this.date.getFullYear()}}</h1>
    <h1 class="text-sm mb-2">Products</h1>

    <div class="flex flex-col hidden md:block">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Product
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Size
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Price
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in order.items" :key="item.product.id" class="bg-white">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                  {{ item.product.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.size }}
                </td>
                <td v-if="item.discount_price === -1 || item.discount_price !== item.product.price" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="line-through">{{ item.product.price }}</span> {{ item.discount_price}} €
                </td>
                <td v-else class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.product.price }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.quantity }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ getItemTotal(item).toFixed(2) }} €
                </td>
              </tr>
              </tbody>

              <tfoot>
              <tr>
                <td colspan="2" class="px-6 py-4 whitespace-nowrap">
                  Total
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ orderTotalLength(order) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ order.paid_amount }} €
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
        v-for="item in order.items" :key="item.product.id"
        class="grid grid-cols-2 text-sm p-2 border-b block md:hidden text-gray-500">
      <div>
        <div>
          {{ item.product.name }}
        </div>
        <div class="text-xs">
          {{ item.product.price }} € <span class="text-xs">x</span> {{ item.quantity }}
        </div>
      </div>
      <div class="text-right">
        <div>
          {{ item.specs || "No size" }}
        </div>
        <div class="text-xs">
          {{ getItemTotal(item).toFixed(2) }} €
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 mt-2">
      <div>
        Total
      </div>
      <div class="text-right">
        {{ order.paid_amount }} €
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "OrderSummary",
  props: {
    order: Object
  },
  data() {
    return {
      date: new Date()
    }
  },
  mounted() {
    this.date = new Date(this.order.created_at)
  },
  methods: {
    getItemTotal(item) {
      if (item.discount_price) {
        return item.quantity * item.discount_price
      } else {
        return item.quantity * item.product.price
      }
    },
    orderTotalLength(order) {
      return order.items.reduce((acc, curVal) => {
        return acc += curVal.quantity
      }, 0)
    }
  }
}
</script>

<style scoped>

</style>
